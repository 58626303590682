import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { setOutputTitle } from '../../../../redux/customOutput';
import { setModalsProps } from '../../../../redux/modals';

const ModalWarnComp = () =>
{
    const { modalsProps } = useSelector((state) => state.modals);

    const { outputTitle,isMissingData} = useSelector((state) => state.customOutput);
    const dispatch = useDispatch();


    return(
    <div className={isMissingData && modalsProps["modalWarningVar"] ? "modal is-active" : "modal"} style={{"zIndex" : 65}}>
        <div className="modal-background"></div>
        <div className='modal-content notification is-warning has-text-centered'>{//<button className="delete" onClick={() => dispatch(setModalsProps({...modalsProps,"modalWarningVar" : false}))}/>
}
            Warning: Some or all of the payors in this dataset do not use the specified time interval. Therefore,
            <br/>  Earnings have been calculated using that data available and are not a perfect representation of the {isMissingData ? <span><b>{outputTitle["YearPeriod"]}</b></span> : null} earnings.<br/><br/>
            <b >Please Choose How you want the data will be present:</b><br/>
            <div className={'select is-small mt-2 '+(outputTitle["yearStatementMethod"] === "" ? "is-danger" : "is-success")}>
                <select onChange={(e) => dispatch(setOutputTitle({...outputTitle,"yearStatementMethod" : e.currentTarget.value}))} value={outputTitle["yearStatementMethod"]}>
                    <option value="">Select...</option>
                    <option value="WithData">Splitting a Amount broadly (with considering the existing information)</option> {/*The Amount will be at the End of the period*/}
                    <option value="WithoutData">Splitting a Amount broadly (without considering the existing information)</option>
                    <option value="LastColumn">The Amount will be at the End of the period</option>
                </select>
            </div>
            <br/><br/>
            <button className='button is-success' onClick={() => {outputTitle["yearStatementMethod"] === "" ?
             alert("Please Choose Method For displaing data") : dispatch(setModalsProps({...modalsProps,"modalWarningVar" : false}))}} >Ok</button>
        </div>
    </div>)
}
export default ModalWarnComp;